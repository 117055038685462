import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | PrimeDrive Rentals
			</title>
			<meta name={"description"} content={"PrimeDrive Rentals ile birinci sınıf araç kiralama hizmetlerini keşfedin. İhtiyaçlarınıza uygun esnek kiralama seçenekleriyle konfor ve stil içinde seyahat edin."} />
			<meta property={"og:title"} content={"Ana Sayfa | PrimeDrive Rentals"} />
			<meta property={"og:description"} content={"PrimeDrive Rentals ile birinci sınıf araç kiralama hizmetlerini keşfedin. İhtiyaçlarınıza uygun esnek kiralama seçenekleriyle konfor ve stil içinde seyahat edin."} />
			<meta property={"og:image"} content={"https://brai-kzflor.com/img/435852341.png"} />
			<link rel={"shortcut icon"} href={"https://brai-kzflor.com/img/435852341.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brai-kzflor.com/img/435852341.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brai-kzflor.com/img/435852341.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brai-kzflor.com/img/435852341.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brai-kzflor.com/img/435852341.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brai-kzflor.com/img/435852341.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(0deg,rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 100%),--color-dark url(https://brai-kzflor.com/img/1-1.jpg) 50% 15%/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="50px"
					text-transform="uppercase"
					font="100 72px/1.2 --fontFamily-sans"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					PrimeDrive Rentals{" "}
				</Text>
			</Box>
			<Box display="flex" margin="-16px -16px -16px -16px" flex-wrap="wrap" width="100%">
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Premium araç kiralama hizmetlerimizle özgürlüğün ve konforun tadını çıkarın
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							PrimeDrive Rentals'a hoş geldiniz, burada seyahatiniz en iyi araç seçimleriyle başlar, tüm seyahat ihtiyaçlarınızı karşılamak için tasarlanmıştır. İster iş seyahati, ister aile tatili veya bireysel bir macera planlıyor olun, konfor, stil ve güvenilirliği garanti eden geniş bir araç yelpazesi sunuyoruz. Müşteri memnuniyeti sağlama taahhüdümüz, herhangi bir durum için mükemmel aracı size sunacağımızdan emin olabileceğiniz anlamına gelir.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							PrimeDrive Rentals'ta geniş filomuz, lüks sedanlar ve geniş SUV'lerden ekonomik kompakt araçlara kadar çeşitli araçları içerir. Her araç, en yüksek kalite ve performans standartlarını sağlamak için titizlikle bakımdan geçer ve düzenli güvenlik kontrollerine tabi tutulur. Bizimle, her seferinde sorunsuz ve keyifli bir sürüş deneyimi sunan en yeni model ünlü markaların araçlarının keyfini çıkarabilirsiniz.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Zamanınızın değerli olduğunu anlıyoruz, bu yüzden rezervasyon sürecimizi olabildiğince basit ve verimli hale getirdik. Sadece birkaç tıklamayla tercih ettiğiniz aracı rezerve edebilir ve gelişinize hazır olmasını sağlayabilirsiniz. Kullanıcı dostu çevrimiçi platformumuz, mevcut araçları incelemenizi, fiyatları karşılaştırmanızı ve bütçenize ve tercihlerinize uygun en iyi seçeneği seçmenizi sağlar.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box padding="80px 32px 0px 32px" border-radius="24px" margin="0px 0px 64px 0px" sm-padding="60px 36px 60px 36px">
				<Box
					width="90%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 6px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Hizmetlerimiz
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Kısa Dönem Kiralama:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					günlük veya haftalık geziler için idealdir.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Uzun Dönem Kiralama:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					uzun süreli kalışlar veya iş seyahatleri için idealdir.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Lüks Araç Kiralama:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					premium araçlarımızla stil sahibi bir sürüş deneyimi yaşayın.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Ekonomik Araç Kiralama:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					tutumlu gezginler için uygun fiyatlı seçenekler.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Şirket Kiralama:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					iş müşterileri için özel çözümler.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer13 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});